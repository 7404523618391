.template {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  .select{
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100px;
    font-size: 14px;
  }
  input{
    width: 70px;
    border: none;
    text-align: center;
    //border-bottom: 1px solid #000;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  input:focus{
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
  }
  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .header-left {
      p {
        margin: 0;
        span {
          font-weight: bold;
        }
      }
    }

    .header-right {
      .stamp {
        padding: 10px;
        text-align: center;
      }
    }
  }

  main {
    .info-section, .result-section, .conclusion {
      margin-bottom: 20px;

      p {
        margin: 5px 0;
        strong {
          font-weight: bold;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        li {
          margin: 5px 0;
        }
      }
    }

    table {
      border-collapse: collapse;
      margin-bottom: 20px;

      th, td {
        border: 1px solid #000;
        padding: 5px;
      }
    }
  }
}
