.login{
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-container{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    width: 100%;
    max-width: 500px;
    background:#fff;
    padding: 20px;
    border-radius:10px;
    .form{
      height: 260px !important;
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}